import { styled } from "@mui/system";
import { Box, Typography } from "@mui/material";
import { CheckBox } from "@mui/icons-material";

export const Container = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  [theme.breakpoints.down("md")]: {
    marginTop: 20,
  },
  [theme.breakpoints.up("md")]: {
    marginTop: 30,
  },
  [theme.breakpoints.up("lg")]: {
    marginTop: 50,
  },
}));

export const Title = styled(Typography)(({ theme }) => ({
  display: "flex",
  flexDirection: "row",
  justifyContent: "center",
  color: "#108D92",
  fontWeight: "bold",
  width: "100%",
  [theme.breakpoints.down("md")]: {
    fontSize: 25,
  },
  [theme.breakpoints.up("md")]: {
    fontSize: 30,
  },
  [theme.breakpoints.up("lg")]: {
    fontSize: 35,
  },
}));

export const ButtonContainer = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "row",
  justifyContent: "space-between",
  width: "85%",
  paddingInline: 5,
  marginTop: 5,
}));

export const ButtonLine = styled(Box)(({ theme }) => ({
  cursor: "pointer",
  display: "flex",
  flexDirection: "row",
  justifyContent: "center",
  padding: 10,
  borderRadius: 10,
  width: "20%",
  [theme.breakpoints.down("md")]: {
    width: "15%",
  },
  [theme.breakpoints.up("lg")]: {
    width: "20%",
  },
}));

export const ButtonDescription = styled(Typography)(({ theme }) => ({
  color: "white",
  [theme.breakpoints.down("md")]: {
    display: "none",
  },
  [theme.breakpoints.up("md")]: {
    display: "flex",
  },
  [theme.breakpoints.up("lg")]: {
    display: "flex",
  },
}));

export const ContentContainer = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "row",
  justifyContent: "center",
  width: "100%",
  alignItems: "center",
  marginTop: 20,
  [theme.breakpoints.up("md")]: {
    marginTop: 20,
  },
  [theme.breakpoints.up("lg")]: {
    marginTop: 20,
  },
}));

export const BookButton = styled(Box)(({ theme }) => ({
  cursor: "pointer",
  backgroundColor: "white",
  color: "#108D92",
  fontWeight: "bold",
  marginTop: "20px",
  marginInline: "auto",
  alignSelf: "center",
  outline: "3px solid #108D92",
  textAlign: "center",
  width: "20%",
  minWidth: 100,
  padding: 15,
  fontSize: 15,
  borderRadius: 15,
  [theme.breakpoints.down("md")]: {
    padding: 10,
    fontSize: 8,
    borderRadius: 10,
  },
}));

export const ItemsImageContainer = styled(Box)(({ theme }) => ({
  [theme.breakpoints.down("md")]: {
    display: "none",
  },
  [theme.breakpoints.up("md")]: {
    display: "flex",
    alignItems: "center",
  },
  [theme.breakpoints.up("lg")]: {
    display: "flex",
    alignItems: "center",
  },
}));

export const ItemsContainer = styled(Box)(({ theme, isSelected }) => ({
  width: "100%",
  backgroundColor: "white",
  outline: "3px solid #D9E4A4",
  height: "450px",
  marginBlock: "20px",
  marginInline: "auto",
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "flex-start",
  border: isSelected ? "5px solid #108D92" : "none",
  opacity: 0.7,
  [theme.breakpoints.down("md")]: {
    width: "70%",
    borderRadius: 5,
    padding: 2,
  },
  [theme.breakpoints.up("md")]: {
    width: "70%",
    right: 120,
    borderRadius: 5,
    padding: 10,
  },
  [theme.breakpoints.up("lg")]: {
    width: "70%",
    right: 200,
    borderRadius: 5,
    padding: 10,
  },
  "&:hover": {
    outline: "3px solid #D9E4A4",
  },
}));

export const ItemDescription = styled(Typography)(({ theme }) => ({
  color: "white",
  [theme.breakpoints.down("md")]: {
    fontSize: 12,
  },
  [theme.breakpoints.up("md")]: {
    fontSize: 20,
  },
  [theme.breakpoints.up("lg")]: {
    fontSize: 20,
  },
}));

export const CustomCheckbox = styled(CheckBox)(({ theme }) => ({
  color: "white",
  [theme.breakpoints.down("md")]: {
    fontSize: 12,
  },
  [theme.breakpoints.up("md")]: {
    fontSize: 20,
  },
  [theme.breakpoints.up("lg")]: {
    fontSize: 20,
  },
}));
