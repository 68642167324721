import { styled } from "@mui/material/styles"
import { Box, Typography } from "@mui/material"

export const MarketingContainer = styled(Box)(({ theme }) => ({
    backgroundColor:"#F8FCE5", 
    borderRadius:10,
    [theme.breakpoints.down("md")]: {
        padding:20, 
        marginInline:10, 
        display:"flex",
        flexDirection:"column",
        height: 450
    },
    [theme.breakpoints.up("md")]: {
        padding:20, 
        marginInline:10, 
        height:650,
    },
    [theme.breakpoints.up("lg")]: {
        padding:25, 
        marginInline:10, 
        height:400,
    },
}));

export const CardTitle = styled(Typography)(({ theme }) => ({
    fontWeight:"bold",
    color: "#108D92",
    textAlign:"center",
    marginBottom:5,
    [theme.breakpoints.down("md")]: {
        fontSize:16,
    },
    [theme.breakpoints.up("md")]: {
        fontSize:20, 
    },
    [theme.breakpoints.up("lg")]: {
        fontSize: 20, 
    },
}));

export const CardDescription = styled(Typography)(({ theme }) => ({
    height:"85%",
    color: "#108D92",
    textAlign:"justify",
    [theme.breakpoints.down("md")]: {
        fontSize:15 ,
    },
    [theme.breakpoints.up("md")]: {
        fontSize:15, 
    },
    [theme.breakpoints.up("lg")]: {
        fontSize: 15, 
    },
}));