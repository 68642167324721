import {
  Box,
  Divider,
  TextField,
  MenuItem,
  Typography,
  Button,
  Modal,
} from "@mui/material";
import {
  bathroomsOptions,
  bedroomsOptions,
  estimatedSquareOptions,
  frequencyOptions,
} from "./../../SelectorsArea/constants";
import {
  BookButton,
  CustomFormControl,
  SelectorLabel,
  SelectorLine,
  TimeModal,
  ModalBox
} from "./style";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import dayjs from "dayjs";
import { useState } from "react";

export default function PhaseOneView(props) {
  const today = dayjs();
  const [open, setOpen] = useState(false);
  const [selectedTime, setSelectedTime] = useState("");
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        maxHeight: 600,
        minHeight: 600,
        justifyContent: "space-between",
      }}
    >
      <Box>
        <SelectorLine>
          <CustomFormControl size="small">
            <TextField
              select
              label="Number of bedrooms"
              size="small"
              value={props.value?.bedrooms}
              onChange={(event) => {
                props.onValueChange.onBedChange(event.target.value);
              }}
            >
              {bedroomsOptions.map((option) => {
                return (
                  <MenuItem
                    key={option}
                    value={option}
                  >{`${option} bedrooms`}</MenuItem>
                );
              })}
            </TextField>
          </CustomFormControl>

          <CustomFormControl size="small">
            <TextField
              select
              label="Number of bathrooms"
              size="small"
              value={props.value?.bathrooms}
              onChange={(event) => {
                props.onValueChange.onBathChange(event.target.value);
              }}
            >
              {bathroomsOptions.map((option) => {
                return (
                  <MenuItem
                    key={`bathroom_${option}`}
                    value={option}
                  >{`${option} bathrooms`}</MenuItem>
                );
              })}
            </TextField>
          </CustomFormControl>
          <CustomFormControl size="small">
            <TextField
              select
              label="Square Feets"
              size="small"
              value={props.value?.square}
              onChange={(event) => {
                props.onValueChange.onSquareChange(event.target.value);
              }}
            >
              {estimatedSquareOptions.map((option) => {
                return (
                  <MenuItem
                    key={option}
                    value={option}
                  >{`${option} sq/ft`}</MenuItem>
                );
              })}
            </TextField>
          </CustomFormControl>
        </SelectorLine>

        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "center",
            gap: "2rem",
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              columnGap: 5,
              rowGap: 2,
              flexWrap: "wrap",
              marginBottom: 5,
            }}
          >
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "center",
                marginTop: 5,
              }}
            >
              <SelectorLabel>Select a frequency</SelectorLabel>
            </Box>

            {frequencyOptions.map((option, i) => (
              <BookButton
                sx={{
                  backgroundColor:
                    props.value.frequency === frequencyOptions[i]
                      ? "#108D92"
                      : "",
                  color:
                    props.value.frequency === frequencyOptions[i]
                      ? "white"
                      : "#108D92",
                  boxShadow: 1,
                  outlineColor: "#108D92",
                }}
                onClick={() => {
                  props.onValueChange.onFreqChange(frequencyOptions[i]);
                  props.setFreqValue(
                    option?.value - (option?.value / 100) * option?.discount
                  );
                  props.setTotalDiscount(option?.discount);
                }}
              >
                {option.name}{" "}
                {option.discount !== 0 ? (
                  <Typography
                    sx={{
                      fontSize: 12,
                    }}
                  >
                    (${option.discount}% OFF)
                  </Typography>
                ) : (
                  ""
                )}
              </BookButton>
            ))}
          </Box>
          <Divider orientation="vertical" flexItem sx={{ marginBottom: 2 }} />
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              flexWrap: "wrap",
              justifyContent: "space-evenly",
              marginBottom: 5,
            }}
          >
            <Box>
              <SelectorLabel>Select a Day</SelectorLabel>
              <DatePicker
                minDate={today}
                onChange={(newDate) => {
                  props.onValueChange.onDateChange(newDate);
                }}
              />
            </Box>
            <Box>
              <SelectorLabel>Select a Time</SelectorLabel>
              <Button sx={{border: "1px solid rgba(0,0,0,.3)", width: "100%", padding: "15px", color: "gray"}}
              onClick={() => setOpen(!open)}>
                {selectedTime ? `${selectedTime}:00 ${selectedTime < 12 ? "AM" : "PM"}` : "HH:MM"}
              </Button>
              <Modal
                open={open}
                onClose={() => setOpen(false)}
                aria-labelledby="child-modal-title"
                aria-describedby="child-modal-description"
              >
                <TimeModal>
                  <h2 id="child-modal-title">Select a time</h2>
                  <ModalBox
                  >
                    {[7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17].map((x) => {
                      return (
                        <Button
                          sx={{
                            backgroundColor:
                              selectedTime === x ? "#40a4a8" : "#FFF",
                            color: selectedTime === x ? "#fff" : "#000",
                          }}
                          onClick={() => {
                            props.onValueChange.onTimeChange(
                              dayjs().startOf("day").hour(x)
                            );
                            setSelectedTime(x);
                            setOpen(false)
                          }}
                        >{`${x}:00 ${x < 12 ? "AM" : "PM"}`}</Button>
                      );
                    })}
                  </ModalBox>
                </TimeModal>
              </Modal>
            </Box>
          </Box>
        </Box>
      </Box>
      <Box>
        <Divider />
      </Box>
    </Box>
  );
}
