import { Box, CircularProgress, Typography } from "@mui/material";
import logo from "./../../../assets/logo.png";
import { BookButton } from "./style";

export default function PhaseFiveView(props) {
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        maxHeight: 600,
        minHeight: 600,
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "center",
          marginTop: 5,
          marginBottom: 5,
        }}
      >
        <img src={logo} alt="logo" style={{ height: 150 }} />
      </Box>
      <Typography sx={{ color: "#108D92", marginBottom: 5 }}>
        One of our teams member will contact you soon to confirm your booking
      </Typography>
      <Typography sx={{ color: "#108D92", marginBottom: 5 }}>
        Thank you for choosign our cleaning services! Your support is what
        motivates us to make your home cleaner and more welcoming everyday!
      </Typography>
      <Typography sx={{ color: "#108D92" }}>Protocol Number</Typography>
      <Typography
        sx={{ color: "#108D92", marginBottom: 5 }}
      >{`#${props.code}`}</Typography>
      <BookButton
        onClick={() => {
          if (!props.isLoading) {
            props.onHandleFifthNextClick();
            props.setIsLoading(true);
          }
        }}
      >
        {props.isLoading ? <CircularProgress color="inherit" /> : "Finish"}
      </BookButton>
    </Box>
  );
}
