import { Box, IconButton } from "@mui/material";
import {InfoLabel, ResponsiveFace, ResponsiveInsta, 
  ResponsiveMail,ResponsiveWhats, ResponsivePhone} from './style'

export default function FooterSection(props) {
  return (
    <Box style={{ width: "100%", height: 100, backgroundColor: "#F8FCE5", display: "flex", flexDirection: "row"}}>
        <Box style={{ width: "25%", height: "100%", display: "flex", flexDirection: "column", rowGap: 5}}>
          <InfoLabel style={{ fontWeight: "bold", marginTop: 15 }}>Official Infos</InfoLabel>
          <InfoLabel>508 840 4907</InfoLabel>
          <InfoLabel>contact@olliemaids.com</InfoLabel>
        </Box>
        <Box style={{ width: "50%", height: "100%", display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "center"}}>
          <InfoLabel>COPYRIGHT 2023 @Ollie Maids</InfoLabel>
        </Box>
        <Box style={{ width: "25%", height: "100%", display: "flex", flexDirection: "column", justifyContent: "center", alignContent: "center", alignItems:"center"}}>
          <Box sx={{display:"flex", justifyContent:"center", alignContent:"center", flexDirection:"row", flexWrap:"wrap"}}>
            <IconButton href="https://www.facebook.com/profile.php?id=100083378863124" target="_blank">
              <ResponsiveFace/>
            </IconButton>
            <IconButton href="https://www.instagram.com/ollie.maids/" target="_blank">
              <ResponsiveInsta/>
            </IconButton>
            <IconButton href="mailto:Natalia@olliemaids.com?body=Hi! I'd like to get a custom free estimate!">
              <ResponsiveMail/>
            </IconButton>
            <IconButton href="https://wa.me/15088404907" target="_blank">
              <ResponsiveWhats/>
            </IconButton>
            <IconButton href="tel:5088404907" target="_blank">
              <ResponsivePhone/>
            </IconButton>
          </Box>
{/*           <BookButton onClick={()=>{props?.onButtonClick()}}>BOOK NOW</BookButton> */}
        </Box>
      </Box>
  );
}
