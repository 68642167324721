import { styled } from "@mui/material/styles";
import { Box, Typography } from "@mui/material";

export const CardExtra = styled(Box)(({ theme }) => ({
  boxShadow: 1,
  outlineColor: "#108D92",
  flexWrap: "wrap",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  cursor: "pointer",
  border: "1px solid rgba(16,141,146, .3)",
  [theme.breakpoints.down("md")]: {
    maxWidth: 70,
    maxHeight: 70,
    minHeight: 70,
    minWidth: 70,
    padding: 15,
    borderRadius: 10,
    marginBottom: 10,
    marginRight: 2,
  },
  [theme.breakpoints.up("md")]: {
    maxWidth: 90,
    maxHeight: 90,
    minHeight: 90,
    minWidth: 90,
    padding: 15,
    borderRadius: 10,
    marginBottom: 10,
    marginRight: 2,
  },
  [theme.breakpoints.up("lg")]: {
    maxWidth: 100,
    maxHeight: 100,
    minHeight: 100,
    minWidth: 100,
    padding: 15,
    borderRadius: 10,
    marginBottom: 10,
    marginRight: 2,
  },
}));

export const LineExtra = styled(Box)(({ theme }) => ({
    gap: '20px',
    [theme.breakpoints.down('md')]: {
        display: 'grid',
        gridTemplateColumns: 'auto auto auto',
        justifyContent: 'space-evenly',
    },
    [theme.breakpoints.up('md')]: {
        display: 'grid',
        gridTemplateColumns: 'auto auto auto auto',
        justifyContent: 'space-evenly',
    },
    [theme.breakpoints.up('lg')]: {
        display: 'grid',
        gridTemplateColumns: 'auto auto auto auto auto',
        justifyContent: 'center',
        columnGap: 30,
    },
}));

export const ExtraCardTitle = styled(Typography)(({ theme }) => ({
  fontSize: "calc(12px + 0.7vw)", // Tamanho base de 14px, com um aumento de 0.7vw

  [theme.breakpoints.up("sm")]: {
    fontSize: 14,
  },
  [theme.breakpoints.up("md")]: {
    fontSize: 19,
  },
}));
