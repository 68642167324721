import { styled } from "@mui/material/styles";
import { Typography, FormControl, Box } from "@mui/material";

export const SelectorLine = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "row",
  justifyContent: "center",
  marginBottom: 5,
  flexWrap: "wrap",
  marginTop: 10,
  rowGap: 5,
  columnGap: 10,
  [theme.breakpoints.down("md")]: {},
  [theme.breakpoints.up("md")]: {},
  [theme.breakpoints.up("lg")]: {},
}));

export const SelectorLabel = styled(Typography)(({ theme }) => ({
  color: "#108D92",
  marginBottom: 5,
  [theme.breakpoints.down("md")]: {
    fontSize: 10,
  },
  [theme.breakpoints.up("md")]: {
    fontSize: 15,
  },
  [theme.breakpoints.up("lg")]: {
    fontSize: 15,
  },
}));

export const CustomFormControl = styled(FormControl)(({ theme }) => ({
  [theme.breakpoints.down("md")]: {
    minWidth: 250,
    fontSize: 10,
  },
  [theme.breakpoints.up("md")]: {
    minWidth: 200,
    fontSize: 15,
  },
  [theme.breakpoints.up("lg")]: {
    minWidth: 250,
    fontSize: 15,
  },
}));

export const TimeModal = styled(Box)(({ theme }) => ({
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 500,
  height: 250,
  backgroundColor: "white",
  padding: "20px",
  border: "1px solid rgba(0, 0, 0, .5)",
  borderRadius: "25px",
  boxShadow: 24,
  pt: 2,
  px: 4,
  pb: 3,
  [theme.breakpoints.down("md")]: {
    width: "80vw",
    height: 250,
    fontSize: 12,
  },
}));
export const ModalBox = styled(Box)(({ theme }) => ({
  display: "grid",
  gridTemplateColumns: "1fr 1fr 1fr 1fr",
  gap: "5px",
  [theme.breakpoints.down("md")]: {
    gridTemplateColumns: "1fr 1fr 1fr",
  },
}));
export const BookButton = styled(Box)(({ theme }) => ({
  cursor: "pointer",
  fontWeight: "bold",
  alignSelf: "center",
  textAlign: "center",
  width: "100%",
  minWidth: 100,
  [theme.breakpoints.down("md")]: {
    padding: 10,
    fontSize: 8,
    borderRadius: 10,
  },
  [theme.breakpoints.up("md")]: {
    padding: 15,
    fontSize: 15,
    borderRadius: 15,
  },
  [theme.breakpoints.up("lg")]: {
    padding: 15,
    fontSize: 15,
    borderRadius: 15,
  },
}));
