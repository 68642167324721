export const data = [
    {
        image: require('./../../assets/cleaningServices/service1.png'),
        title: "Residential Cleaning",
        description: "We specialize in cleaning floors, bedrooms, bathrooms, and kitchens!"
    },
    {
        image: require('./../../assets/cleaningServices/service2.png'),
        title: "Commercial cleaning",
        description: "A commercial cleaning service cleans things like offices, buildings or retail stores. There’s a big difference in how you clean a retail store and someone’s home."
    },
    {
        image: require('./../../assets/cleaningServices/service3.png'),
        title: "Move in | out cleaning",
        description: "All Move-In or Move-Out Classic Package Cleanings will include the following: Dust Top-to-Bottom: every horizontal and vertical surface from ceilings to the floors, including fans, blinds, cabinets, etc. Wipe Mirrors, Glass Doors. Vacuum Inside All Cabinets, Closets/Shelves, and Drawers."
    },
    {
        image: require('./../../assets/cleaningServices/service4.png'),
        title: "Post construction cleaning",
        description: "Post-construction cleaning is the service done to new or renovated buildings. It's understood that this cleaning is done after the construction part. A construction contractor may do a general cleaning of debris, but detailed cleaning is not part of their job anymore."
    },
    {
        image: require('./../../assets/cleaningServices/service5.png'),
        title: "Airbnb cleaning",
        description: "Cleaning around the unit, such as cleaning floors, bedrooms, making all beds, bathrooms with a thorough wash of the toilets, showers, and bathtub. Kitchens cleaning and putting away dishes. Emptying all trash and recycling cans and refill basics of the unit as shampoo, conditionate, body wash, hand wash and dish soap."
    },
    {
        image: require('./../../assets/cleaningServices/service6.png'),
        title: "Students cleaning",
        description: "Understanding that the routine of the students is very intense, we are happy to welcome Boston Students and be your extra hand."
    }
]