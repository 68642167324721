import { Box, Divider, TextField, Typography } from "@mui/material";

export default function PhaseThreeView(props) {
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        maxHeight: 600,
        minHeight: 600,
        justifyContent: "space-between",
      }}
    >
      <Box>
        <Box sx={{ display: "flex", flexDirection: "row" }}>
          <Typography sx={{ color: "#108D92", marginBottom: 2 }}>
            Personal Informations
          </Typography>
        </Box>
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            marginBottom: 5,
            marginInline: 5,
            rowGap: 2,
          }}
        >
          <TextField
            label="First Name"
            variant="outlined"
            value={props.value.firstName}
            onChange={(event) => {
              props.onValueChange.onFirstNameChange(event.target.value);
            }}
            sx={{ width: "45%" }}
          />
          <TextField
            label="Last Name"
            variant="outlined"
            value={props.value.lastName}
            onChange={(event) => {
              props.onValueChange.onLastNameChange(event.target.value);
            }}
            sx={{ width: "45%" }}
          />
        </Box>
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            marginBottom: 5,
            marginInline: 5,
          }}
        >
          <TextField
            label="Email"
            variant="outlined"
            value={props.value.email}
            onChange={(event) => {
              props.onValueChange.onEmailChange(event.target.value);
            }}
            sx={{ width: "45%" }}
          />
          <TextField
            label="Phone Number"
            variant="outlined"
            value={props.value.phoneNumber}
            onChange={(event) => {
              props.onValueChange.onPhoneNumberChange(event.target.value);
            }}
            sx={{ width: "45%" }}
          />
        </Box>
        <Divider />
        <Box sx={{ display: "flex", flexDirection: "row" }}>
          <Typography sx={{ color: "#108D92", marginBottom: 2 }}>
            Location Informations
          </Typography>
        </Box>
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            marginBottom: 5,
            marginInline: 5,
          }}
        >
          <TextField
            label="Address"
            variant="outlined"
            value={props.value.address}
            onChange={(event) => {
              props.onValueChange.onAddressChange(event.target.value);
            }}
            sx={{ width: "45%" }}
          />
          <TextField
            label="Apt Suite"
            variant="outlined"
            value={props.value.suite}
            onChange={(event) => {
              props.onValueChange.onSuiteChange(event.target.value);
            }}
            sx={{ width: "45%" }}
          />
        </Box>
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            marginBottom: 5,
            marginInline: 5,
          }}
        >
          <TextField
            label="City"
            variant="outlined"
            value={props.value.city}
            onChange={(event) => {
              props.onValueChange.onCityChange(event.target.value);
            }}
            sx={{ width: "30%" }}
          />
          <TextField
            label="State"
            variant="outlined"
            value={props.value.state}
            onChange={(event) => {
              props.onValueChange.onStateChange(event.target.value);
            }}
            sx={{ width: "30%" }}
          />
          <TextField
            label="Zip Code"
            variant="outlined"
            value={props.value.zipCode}
            onChange={(event) => {
              props.onValueChange.onZipCodeChange(event.target.value);
            }}
            sx={{ width: "30%" }}
          />
        </Box>
      </Box>
      <Box sx={{ display: "flex", flexDirection: "column" }}>
        <Divider />
      </Box>
    </Box>
  );
}
