export const bedroomsOptions = [0,1,2,3,4,5,6,7,8,9]

export const bathroomsOptions = [0,1,2,3,4,5,6,7,8,9]

export const frequencyOptions = [
    {
        name: "One time Cleaning",
        value: 228.00,
        discount: 0
    },
    {
        name: "Weekly Cleaning",
        value: 228,
        discount: 25
    },
    {
        name: "Bi-Weekly Cleaning",
        value: 228,
        discount: 20
    },
    {
        name: "Every 3 Weeks",
        value: 228,
        discount: 15
    },
    {
        name: "Monthly Cleaning",
        value: 228,
        discount: 10
    },
]

export const estimatedSquareOptions= [
    "0-500",
    "500-1000",
    "1000-1500",
    "1500-2000",
    "2001-2500",
    "2501-3000",
    "3001-3500",
    "3501-4000",
    "4501-5000",
    "5001-5500",
    "5500-6000",
    "15500-16000",
]
