import { styled } from "@mui/material/styles";
import {
    Box,Typography
} from "@mui/material";

export const VideoHeader = styled(Box)(({ theme }) => ({
    overflow: "hidden",
    [theme.breakpoints.down("md")]: {
      height: 200,
    },
    [theme.breakpoints.up("md")]: {
      height: 400,
    },
    [theme.breakpoints.up("lg")]: {
      height: 500,
    },
}));

export const LogoLocal = styled(Box)(({ theme }) => ({
    position: "absolute",
    backgroundColor: "white",
    top: 0,
    right: 0,
    opacity: 0.8,
    display: "flex",
    justifyContent: "center",
    alignItems: "flex-end",
    width: "25%",
    [theme.breakpoints.down("md")]: {
      height: 200,
    },
    [theme.breakpoints.up("md")]: {
      height: 400,
    },
    [theme.breakpoints.up("lg")]: {
      height: 500,
    },
  }));
  
export const Logo= styled(Box)(({ theme }) => ({
      overflow: "hidden",
      [theme.breakpoints.down("md")]: {
          width: 100, 
          height: 70
      },
      [theme.breakpoints.up("md")]: {
          width: 150, 
          height: 150
      },
      [theme.breakpoints.up("lg")]: {
          width: 200, 
          height: 200
      },
}));

export const MarkTitle= styled(Typography)(({ theme }) => ({
    color: "#108D92",
    fontWeight: "bold",    
    [theme.breakpoints.down("md")]: {
        marginTop: 5,
        fontSize: 15,
    },
    [theme.breakpoints.up("md")]: {
        marginTop: 10,
        fontSize: 20,
    },
    [theme.breakpoints.up("lg")]: {
        fontSize: 35,
    },
}));

export const MarkTitleLine2= styled(Typography)(({ theme }) => ({
    color: "#108D92",
    fontWeight: "bold",    
    [theme.breakpoints.down("md")]: {
        fontSize: 15,
    },
    [theme.breakpoints.up("md")]: {
        fontSize: 20,
    },
    [theme.breakpoints.up("lg")]: {
        fontSize: 35,
    },
}));

export const SmallDescription= styled(Typography)(({ theme }) => ({
    color: "#108D92", 
    textAlign:"center",
    [theme.breakpoints.down("md")]: {
        fontSize: 10,
    },
    [theme.breakpoints.up("md")]: {
        fontSize: 15,
    },
    [theme.breakpoints.up("lg")]: {
        fontSize: 20,
    },
}));
