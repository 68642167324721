import { CleaningServices, Bed, Blender, Bathtub } from '@mui/icons-material';
export const data = [
    {
        title: "Kitchen",
        image: require('./../../assets/cleaningChecklist/checklist1.jpg'),
        icon: <Blender sx={{ color: "white" }}/>,
        items: [
            "Clean exterior of ALL Appliances",
            "Tables/bar tops/chairs",
            "Countertops & backsplash",
            "Sink",
            "Empty trash",
            "Clean exterior of  cabinets (fingerprints)",
            "Faucet (if applicable)",
            "Cabinet fixtures",
            "Vacuum (if applicable)",
            "Load dishwaser (if applicable)",
        ]
    },
    {
        title: "Bathrooms",
        image: require('./../../assets/cleaningChecklist/checklist2.jpg'),
        icon: <Bathtub sx={{ color: "white" }}/>,
        items: [
            'Clean tub/shower tiles',
            'Clean and disinfect toilets',
            'Clean cabinet fixtures',
            'Clean countertops',
            'Cabinet doors',
            'Sink',
            'Mop floors',
            'Empty trash',
            'Clean mirror',
            'Faucet',
            'Vacuum (if applicable)',
        ]
    },
    {
        title: "Bedrooms",
        image: require('./../../assets/cleaningChecklist/checklist3.jpg'),
        icon: <Bed sx={{ color: "white" }}/>,
        items: [
            'Make bed(s)',
            'Dust and wipe lamps',
            'Dust picture frames (if applicable)',
            'Dust exposed surfaces',
            'Vacuum (if applicable)',
            'Basic Organization (< 5 Minutes)',
            'Clean mirrors (if applicable)',
            'Empty trash (if applicable)',
        ]
    },
    {
        title: "All other common",
        image: require('./../../assets/cleaningChecklist/checklist4.jpg'),
        icon: <CleaningServices sx={{ color: "white" }}/>,
        items: [
            'Arrange cushions',
            'Dust any décor',
            'Dust all tabletops',
            'Mop (if applicable)',
            'Vacuum (if applicable)',
            'Load dishwasher (if applicable)',
            'Clean mirrors',
            'Basic Organization (< 5 Minutes)',
            'Empty trash',
        ]
    }
]