import { Box } from "@mui/material";
import { BookButton } from "./style";
import { steps } from "..";

const CardFooter = ({
  total,
  discount,
  phase,
  setActiveStep,
  onHandleOneNextClick,
  onHandleThreeNextClick,
  onHandleFifthNextClick,
}) => {
  const validateForm = () => {
    switch (phase) {
      case 0:
        onHandleOneNextClick();
        break;
      case 2:
        onHandleThreeNextClick();
        break;
      case 4:
        onHandleFifthNextClick();
        break;
      default:
        setActiveStep(phase + 1);
        break;
    }
  };

  return (
    <Box
      sx={{
        paddingInline: 5,
        marginTop: 2,
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between",
        alignItems: "center",
        width: "80%",
      }}
    >
      {phase > 0 ? (
        <BookButton
          onClick={() => {
            setActiveStep(phase - 1);
          }}
        >
          Back
        </BookButton>
      ) : (
        <></>
      )}

      {phase < 4 ? (
        <Box sx={{
          display: "flex",
          justifyContent: "flex-end",
          width: "100%"
        }}>
          

          <BookButton
            onClick={() => {
              validateForm();
            }}
          >
            {phase === steps.length ? "Finish" : "Next"}
          </BookButton>
        </Box>
      ) : (
        <></>
      )}
    </Box>
  );
};

export default CardFooter;
