import video_head from "./../../assets/video_head.mp4";
import logo from "./../../assets/logo.png";
import { VideoHeader, LogoLocal, Logo, MarkTitle, MarkTitleLine2, SmallDescription } from './style'
import { Box } from "@mui/material";
import React, {useRef, useEffect} from "react"

export default function VideoHeaderSection({props}) {
  const videoRef = useRef(undefined);
  useEffect(() => {
    videoRef.current.defaultMuted = true;
  })

  return (
    <Box>
        <VideoHeader>
            <video src={video_head} style={{ height: "auto", width: "100%" }} ref={videoRef} autoPlay muted loop playsInline/>
        </VideoHeader>
        <LogoLocal>
        <Box
        style={{ width: "50%", height: "90%", display: "flex",
            flexDirection: "column", padding: 20, justifyContent: "center", alignItems: "center"}}
        >
            <img style={{height:"60%"}} src={logo}/>
            <SmallDescription>More than 15 years of experience</SmallDescription>
        </Box>
    </LogoLocal>
  </Box>
  );
}
