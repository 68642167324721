import { Box, Divider, Typography } from "@mui/material";

export default function PhaseFourView({
  discount,
  totalValue,
  value,
  extraValue,
}) {
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        maxHeight: 600,
        minHeight: 600,
        justifyContent: "space-between",
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-around",
          height: "100vh",
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
            marginTop: 5,
            marginBottom: 5,
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "flex-start",
            }}
          >
            <Typography sx={{ marginBottom: 2, color: "#108D92" }}>
              General Information
            </Typography>
            <Typography>{value?.firstName}</Typography>
            <Typography>{value?.lastName}</Typography>
            <Typography>{value?.email}</Typography>
            <Typography>{value?.phoneNumber}</Typography>
          </Box>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "flex-start",
            }}
          >
            <Typography>{value?.address}</Typography>
            <Typography>{value?.suite}</Typography>
            <Typography>{value?.city}</Typography>
            <Typography>{value?.state}</Typography>
            <Typography>{value?.zipCode}</Typography>
          </Box>
        </Box>
        <Divider orientation="vertical" flexItem sx={{ marginBottom: 2 }} />
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
            marginTop: 5,
            marginBottom: 5,
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "flex-start",
            }}
          >
            <Typography sx={{ marginBottom: 2, color: "#108D92" }}>
              Cleaning Service Information
            </Typography>
            <Typography>{`${value?.bedrooms} bedrooms`}</Typography>
            <Typography>{`${value?.bathrooms} bathrooms`}</Typography>
            <Typography>{value?.frequency?.name}</Typography>
            <Typography>{`${value?.square} sq/ft`}</Typography>
          </Box>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "flex-start",
            }}
          >
            <Typography sx={{ marginBottom: 2, color: "#108D92" }}>
              Service Schedule
            </Typography>
            <Typography>{`${new Date(
              value?.date
            ).toLocaleDateString()}`}</Typography>
            <Typography>{`${new Date(
              value?.time
            ).toLocaleTimeString()}`}</Typography>
          </Box>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "flex-start",
            }}
          >
            <Typography sx={{ marginBottom: 2, color: "#108D92" }}>
              Extras
            </Typography>
            {value?.extras &&
              value?.extras?.map((extra) => {
                return <Typography>{extra.title}</Typography>;
              })}
            <Typography>{value?.observations}</Typography>
          </Box>
        </Box>
        <Divider orientation="vertical" flexItem sx={{ marginBottom: 2 }} />
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
            marginTop: 5,
            marginBottom: 5,
          }}
        >
          <Box sx={{ display: "flex", flexDirection: "column", rowGap: 5 }}>
            <Typography sx={{ marginBottom: 2, color: "#108D92" }}>
              Value Description
            </Typography>
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
              }}
            >
              <Typography>Subtotal</Typography>
              <Typography>
              US$ {(totalValue / (1 - (discount / 100))).toFixed(2)}
              </Typography>
            </Box>
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
              }}
            >
              <Typography>Extras</Typography>
              <Typography>US$ {extraValue.toFixed(2)}</Typography>
            </Box>
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
              }}
            >
              <Typography sx={{ color: "red" }}>Discount</Typography>
              <Typography sx={{ color: "red" }}>{discount}%</Typography>
            </Box>
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
              }}
            >
              <Typography>Total</Typography>
              <Typography>US$ {totalValue}</Typography>
            </Box>
          </Box>
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              columnGap: 2,
              padding: 2,
              backgroundColor: "#D9E4A4",
              borderRadius: 5,
            }}
          >
            <Typography>TOTAL</Typography>
            <Typography sx={{ fontWeight: "bold" }}>
              US$ {totalValue.toFixed(2)}
            </Typography>
          </Box>
        </Box>
      </Box>
      <Box>
        <Divider />
      </Box>
    </Box>
  );
}
