import { Stepper, Step, StepLabel, Typography, Box } from "@mui/material";
import React, { useState } from "react";
import PhaseOneView from "./PhaseOneView";
import PhaseTwoView from "./PhaseTwoView";
import PhaseThreeView from "./PhaseThreeView";
import PhaseFourView from "./PhaseFourView";
import { Container, FormBox, FormsTitle } from "./style";
import { forwardRef, useEffect } from "react";
import PhaseFiveView from "./PhaseFiveView";
import CardFooter from "./CardFooter";
import emailjs from "@emailjs/browser";
import axios from "axios";
import dayjs from "dayjs";
import toast, { Toaster } from "react-hot-toast";
import { estimatedSquareOptions } from "../SelectorsArea/constants";
import Swal from "sweetalert2";

export const steps = ["", "", "", "", ""];

const PhasedForm = forwardRef((props, ref) => {
  const [activeStep, setActiveStep] = useState(0);
  const [finalValue, setFinalValue] = useState(100);
  const [totalDiscount, setTotalDiscount] = useState(0);
  const [bathrooms, setBathrooms] = useState(props.values.bathrooms);
  const [bedrooms, setBedrooms] = useState(props.values.bedrooms);
  const [square, setSquare] = useState(props.values.square);
  const [frequency, setFrequency] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const [date, setDate] = useState("");
  const [time, setTime] = useState("");

  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [address, setAddress] = useState("");
  const [suite, setSuite] = useState("");
  const [city, setCity] = useState("");
  const [state, setState] = useState("");
  const [zipCode, setZipCode] = useState("");
  const [extras, setExtras] = useState([]);
  const [observation, setObservation] = useState("");

  const [freqCalcValue, setFreqCalcValue] = useState(0);
  const [extraCalcValue, setExtraCalcValue] = useState(0);
  const [sqCalcValue, setSqCalcValue] = useState(0);
  const [bathCalcValue, seBathCalcValue] = useState(0);
  const [bedroomCalcValue, setBedroomCalcValue] = useState(0);

  const url = "https://olliemaids-api-new.vercel.app/create-event";
  //const url = "http://localhost:8000/create-event";

  function thirdFormValidation() {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    const numberRegex = /^\d+$/;

    if (!firstName || firstName.length < 3)
      return "Please, inform your First Name";
    else if (!lastName || lastName.length < 3)
      return "Please, inform your Last Name";
    else if (!email || email.length < 5) return "Please, inform your email";
    else if (!emailRegex.test(email)) return "Please, inform a valid email";
    else if (!phoneNumber || phoneNumber.length < 5)
      return "Please, inform your phone number";
    else if (!numberRegex.test(phoneNumber))
      return "Please, inform a valid phone number (only digits)";
    else if (!address || address.length < 4)
      return "Please, inform your Address";
    else if (!suite) return "Please, inform your apartment number";
    else if (!city || city.length < 4) return "Please, inform your city";
    else if (!state) return "Please, inform your state";
    else if (!zipCode || zipCode.length < 4)
      return "Please, inform your ZipCode";
    else if (!numberRegex.test(zipCode))
      return "Please, inform a valid zipcode";

    return;
  }

  function cleanInputs() {
    setFinalValue(0);
    setTotalDiscount(0);
    setDate("");
    setTime("");

    setFirstName("");
    setLastName("");
    setEmail("");
    setPhoneNumber("");
    setAddress("");
    setSuite("");
    setCity("");
    setState("");
    setZipCode("");
    setExtras([]);
    setObservation("");
  }

  const onHandleAddExtra = (extra) => {
    if (extras.some((s) => s.title === extra.title)) {
      setExtras(extras.filter((f) => f.title !== extra.title));
      setExtraCalcValue((prev) => prev - extra.value);
    } else {
      setExtras([...extras, extra]);
      setExtraCalcValue((prev) => prev + extra.value);
    }
  };
  const onHandleAddSqft = (sq) => {
    const index = estimatedSquareOptions.findIndex((x) => x === sq);
    const increment = index * 5;
    setSqCalcValue(increment);
  };
  const onHandleAddBathroom = (bt) => {
    if(bt !== 0) bt = bt - 1
    const increment = bt * 20;
    seBathCalcValue(increment);
  };
  const onHandleAddBedroom = (bd) => {
    if(bd !== 0) bd = bd - 1
    const increment = bd * 10;
    setBedroomCalcValue(increment);
  };

  const validateStepOne = () => {
    if (!frequency) {
      return "frequency";
    } else if (!date) {
      return "schedule date";
    } else if (!time) {
      return "schedule time";
    }
    return;
  };

  const onHandleOneNextClick = () => {
    const message = validateStepOne();

    if (!message) setActiveStep(activeStep + 1);
    else toast.error(`Please select a ${message}`);
  };

  const onHandleThreeNextClick = () => {
    var validation = thirdFormValidation();
    if (validation) {
      toast.error(validation);
    } else setActiveStep(activeStep + 1);
  };

  const onHandleFifthNextClick = async () => {
    const jsDate = dayjs(date);
    const jsTime = dayjs(time);
    const newDate = jsDate.format("YYYY-MM-DD");
    const newTime = jsTime.format("HH:mm a");
    const daysOfWeek = [
      "Sunday",
      "Monday",
      "Tuesday",
      "Wednesday",
      "Thursday",
      "Friday",
      "Saturday",
    ];
    const dayOfWeek = jsDate.day();

    const templateParams = {
      from_name: `${firstName} ${lastName}`,
      message: `
\nSchedule date: ${newDate} (${daysOfWeek[dayOfWeek]}), ${newTime}
\nClient: ${firstName} ${lastName}
\nE-mail: ${email}
\nPhone: ${phoneNumber}
\nAddress: ${address}, No ${suite}
\nCity: ${city}
\nState: ${state}
\nZip-code: ${zipCode}
\n
\n${frequency.name},
\nbedrooms: ${bedrooms}
\nbathrooms: ${bathrooms}
\nsuqares sq/ft: ${square}
\n
\nExtras: \n${extras.map((x) => `- ${x.title}`).join("\n")}
\nObservation: ${observation}
\nTotal: US$ ${finalValue.toFixed(2)}
      `,
    };

    const dateTime = dayjs(newDate + " " + newTime, "YYYY-MM-DD hh:mm A");
    const event = {
      summary: `Cleaning Schedulle ${newTime}`,
      description: `${firstName} ${lastName}, ${frequency.name}\n ${address} No ${suite}, ${city}`,
      start: {
        dateTime: dateTime,
        timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
      },
      end: {
        dateTime: dateTime.add(1, "hour"),
        timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
      },
    };

    // Configuração da requisição
    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };
    axios
      .post(url, event, config)
      .then(() => {
        emailjs.send(
          "service_i3f9wlr",
          "template_ncqf6lg",
          templateParams,
          "ARN_auj6k0A3BT6pT"
        );
        setIsLoading(false);
        setActiveStep(0);
        cleanInputs();

        Swal.fire({
          position: "center",
          icon: "success",
          title: "Your schedulle was made successfully",
          showConfirmButton: false,
          timer: 1500
        });
      })
      .catch((error) => {
        setIsLoading(false);
        Swal.fire({
          position: "center",
          icon: "error",
          title: "Error while making your request. Try again later",
          showConfirmButton: false,
          timer: 1500
        });
      });
  };

  useEffect(() => {
    setBathrooms(props.values.bathrooms);
    setBedrooms(props.values.bedrooms);
    setSquare(props.values.square);
    setFrequency(props.values.frequency);

    const phase1Value =
      sqCalcValue + extraCalcValue + bedroomCalcValue + bathCalcValue + 125;
    setFinalValue(phase1Value - (phase1Value / 100) * totalDiscount);
  }, [
    bathCalcValue,
    bedroomCalcValue,
    extraCalcValue,
    props.values.bathrooms,
    props.values.bedrooms,
    props.values.frequency,
    props.values.square,
    sqCalcValue,
    totalDiscount,
  ]);

  return (
    <Container ref={ref}>
      <FormsTitle>Fill your booking here!</FormsTitle>
      <FormBox>
        <Box
          sx={{
            display: "flex",
            columnGap: 3,
            position: "relative",
            justifyContent: "center",
          }}
        >
          <Typography
            sx={{
              fontSize: "15pt",
            }}
          >{`${finalValue.toLocaleString("en-US", {
            style: "currency",
            currency: "USD",
          })}`}</Typography>
          <Typography
            sx={{
              fontSize: "15pt",
            }}
          >{`${totalDiscount}% OFF`}</Typography>
        </Box>
        <Stepper
          activeStep={activeStep}
          alternativeLabel
          sx={{
            "& .MuiStepLabel-root .Mui-completed": {
              color: "#108D92", // circle color (COMPLETED)
            },
            "& .MuiStepLabel-label.Mui-completed.MuiStepLabel-alternativeLabel":
              {
                color: "grey.500", // Just text label (COMPLETED)
              },
            "& .MuiStepLabel-root .Mui-active": {
              color: "#108D92", // circle color (ACTIVE)
            },
            "& .MuiStepLabel-label.Mui-active.MuiStepLabel-alternativeLabel": {
              color: "common.white", // Just text label (ACTIVE)
            },
            "& .MuiStepLabel-root .Mui-active .MuiStepIcon-text": {
              fill: "common.white", // circle's number (ACTIVE)
            },
          }}
        >
          {steps.map((label) => (
            <Step key={label}>
              <StepLabel>{label}</StepLabel>
            </Step>
          ))}
        </Stepper>
        {activeStep === 0 && (
          <PhaseOneView
            discount={totalDiscount}
            setTotalDiscount={setTotalDiscount}
            setFreqValue={setFreqCalcValue}
            totalValue={finalValue}
            value={{ bedrooms, bathrooms, square, frequency, date, time }}
            onValueChange={{
              onBedChange: (newValue) => {
                onHandleAddBedroom(newValue);
                props.onValueChange.onBedChange(newValue);
              },
              onBathChange: (newValue) => {
                onHandleAddBathroom(newValue);
                props.onValueChange.onBathChange(newValue);
              },
              onFreqChange: (newValue) => {
                props.onValueChange.onFreqChange(newValue);
              },
              onSquareChange: (newValue) => {
                onHandleAddSqft(newValue);
                props.onValueChange.onSquareChange(newValue);
              },
              onDateChange: (newValue) => {
                setDate(newValue);
              },
              onTimeChange: (newValue) => {
                setTime(newValue);
              },
            }}
          />
        )}

        {activeStep === 1 && (
          <PhaseTwoView
            discount={totalDiscount}
            totalValue={finalValue}
            value={{ extras, observation }}
            onValueChange={{
              onExtraChange: (newValue) => {
                onHandleAddExtra(newValue);
              },
              onObsChange: (newValue) => {
                setObservation(newValue);
              },
            }}
          />
        )}

        {activeStep === 2 && (
          <PhaseThreeView
            discount={totalDiscount}
            totalValue={finalValue}
            value={{
              firstName,
              lastName,
              email,
              phoneNumber,
              address,
              suite,
              city,
              state,
              zipCode,
            }}
            onValueChange={{
              onFirstNameChange: (newValue) => {
                setFirstName(newValue);
              },
              onLastNameChange: (newValue) => {
                setLastName(newValue);
              },
              onEmailChange: (newValue) => {
                setEmail(newValue);
              },
              onPhoneNumberChange: (newValue) => {
                setPhoneNumber(newValue);
              },
              onAddressChange: (newValue) => {
                setAddress(newValue);
              },
              onSuiteChange: (newValue) => {
                setSuite(newValue);
              },
              onCityChange: (newValue) => {
                setCity(newValue);
              },
              onStateChange: (newValue) => {
                setState(newValue);
              },
              onZipCodeChange: (newValue) => {
                setZipCode(newValue);
              },
            }}
          />
        )}

        {activeStep === 3 && (
          <PhaseFourView
            discount={totalDiscount}
            totalValue={finalValue}
            extraValue={extraCalcValue}
            value={{
              firstName,
              lastName,
              email,
              phoneNumber,
              address,
              suite,
              city,
              state,
              zipCode,
              bedrooms,
              bathrooms,
              square,
              frequency,
              extras,
              observation,
              date,
              time,
            }}
          />
        )}

        {activeStep === 4 && (
          <PhaseFiveView
            code={"012345"}
            isLoading={isLoading}
            setIsLoading={setIsLoading}
            onHandleFifthNextClick={onHandleFifthNextClick}
          />
        )}
      </FormBox>
      <CardFooter
        total={finalValue}
        discount={totalDiscount}
        phase={activeStep}
        setActiveStep={setActiveStep}
        onHandleOneNextClick={() => onHandleOneNextClick()}
        onHandleThreeNextClick={onHandleThreeNextClick}
      />
      <Toaster position="top-right" reverseOrder={false} />
    </Container>
  );
});

export default PhasedForm;
