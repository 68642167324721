import { styled } from "@mui/material/styles";
import {Box,Typography} from "@mui/material";
import { Facebook, Email, Instagram, WhatsApp, Phone } from "@mui/icons-material";

export const VideoHeader = styled(Box)(({ theme }) => ({
    overflow: "hidden",
    [theme.breakpoints.down("md")]: {
      height: 200,
    },
    [theme.breakpoints.up("md")]: {
      height: 400,
    },
    [theme.breakpoints.up("lg")]: {
      height: 500,
    },
}));

export const InfoLabel= styled(Typography)(({ theme }) => ({
    marginLeft:15,
    color: "#108D92",
    [theme.breakpoints.down("md")]: {
        fontSize: 10,
    },
    [theme.breakpoints.up("md")]: {
        fontSize: 15,
    },
    [theme.breakpoints.up("lg")]: {
        fontSize: 15,
    },
}));

export const ResponsiveFace= styled(Facebook)(({ theme }) => ({
  color: "#108D92",
  [theme.breakpoints.down("md")]: {
    fontSize: 10,
  },
  [theme.breakpoints.up("md")]: {
      fontSize: 25,
  },
  [theme.breakpoints.up("lg")]: {
      fontSize: 40,
  },
}));

export const ResponsiveInsta= styled(Instagram)(({ theme }) => ({
  color: "#108D92",
  [theme.breakpoints.down("md")]: {
    fontSize: 10,
  },
  [theme.breakpoints.up("md")]: {
      fontSize: 25,
  },
  [theme.breakpoints.up("lg")]: {
      fontSize: 40,
  },
}));

export const ResponsiveMail= styled(Email)(({ theme }) => ({
  color: "#108D92",
  [theme.breakpoints.down("md")]: {
    fontSize: 10,
  },
  [theme.breakpoints.up("md")]: {
      fontSize: 25,
  },
  [theme.breakpoints.up("lg")]: {
      fontSize: 40,
  },
}));

export const ResponsiveWhats= styled(WhatsApp)(({ theme }) => ({
  color: "#108D92",
  [theme.breakpoints.down("md")]: {
    fontSize: 10,
  },
  [theme.breakpoints.up("md")]: {
      fontSize: 25,
  },
  [theme.breakpoints.up("lg")]: {
      fontSize: 40,
  },
}));

export const ResponsivePhone = styled(Phone)(({ theme }) => ({
  color: "#108D92",
  [theme.breakpoints.down("md")]: {
    fontSize: 10,
  },
  [theme.breakpoints.up("md")]: {
      fontSize: 25,
  },
  [theme.breakpoints.up("lg")]: {
      fontSize: 40,
  },
}));

export const BookButton= styled(Box)(({ theme }) => ({
  cursor: "pointer",
  backgroundColor: "#108D92",
  color: "white",
  fontWeight: "bold",
  width: "70%",
  borderRadius:10,
  textAlign:"center",
  [theme.breakpoints.down("md")]: {
    fontSize: 8,
    padding:10
  },
  [theme.breakpoints.up("md")]: {
      fontSize: 15,
      padding:10
  },
  [theme.breakpoints.up("lg")]: {
      fontSize: 15,
      padding:10
  },
}));