import { CheckBox } from "@mui/icons-material";
import { Box } from "@mui/material";
import { useRef, useState } from "react";
import { data } from "./constants";
import Carousel from "react-elastic-carousel";
import {
  ButtonContainer,
  ButtonDescription,
  ButtonLine,
  Container,
  ContentContainer,
  ItemsContainer,
  Title,
} from "./style";

export default function CleaningChecklist(props) {
  const [value, setValue] = useState(-1);
  const carouselRef = useRef(null);
  let resetTimeout;

  const breakPoints = [
    { width: 1, itemsToShow: 1 },
    { width: 550, itemsToShow: 1 },
    { width: 768, itemsToShow: 3 },
  ];

  const handleChange = (newValue) => {
    setValue(newValue);

    carouselRef.current.goTo(newValue);
  };

  if (!data) return;

  return (
    <Container>
      <Title>Cleaning Checklist</Title>
      <ButtonContainer>
        {data.map((types, index) => {
          return (
            <ButtonLine
              sx={{ backgroundColor: index == value ? "#108D92" : "#D9E4A4" }}
              onClick={() => {
                handleChange(index);
              }}
            >
              {types.icon}
              <ButtonDescription>{types.title}</ButtonDescription>
            </ButtonLine>
          );
        })}
      </ButtonContainer>

      <ContentContainer>
        <Carousel
          ref={carouselRef}
          breakPoints={breakPoints}
          pagination={false}
          enableAutoPlay={value == -1}
          showArrows={false}
          autoPlaySpeed={5000}
          isRTL={false}
          onNextEnd={({ index }) => {
            clearTimeout(resetTimeout);
            resetTimeout = setTimeout(() => {
              carouselRef?.current?.goTo(0);
            }, 5000);
          }}
        >
          {data?.map((item, index) => (
            <ItemsContainer key={index} isSelected={index === value}>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  columnGap: 3,
                  alignItems: "center",
                  padding: "20px 0px",
                }}
              >
                <h1
                  style={{
                    color: "#108D92",
                    fontSize: 26,
                    textAlign: "center",
                    width: "100%",
                    marginBlockStart: 0,
                  }}
                >
                  {item.title}
                </h1>
                <div
                  style={{
                    color: "#108D92",
                    width: "100%",
                    fontWeight: "bold",
                  }}
                >
                  {item?.items?.map((itemText, itemIndex) => (
                    <div
                      key={itemIndex}
                      style={{
                        paddingBlock: "2px",
                        display: "flex",
                        alignItems: "center",
                        gap: "10px",
                      }}
                    >
                      <CheckBox sx={{ color: "#108D92", fontWeight: "bold" }} />
                      {itemText}
                    </div>
                  ))}
                </div>
              </Box>
            </ItemsContainer>
          ))}
        </Carousel>
      </ContentContainer>
    </Container>
  );
}
