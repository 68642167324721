import { Box } from "@mui/material";
import { useRef, useState } from "react";
import VideoHeaderSection from "../../components/VideoHeader";
import FooterSection from "../../components/Footer";
import CleaningChecklist from "../../components/CleaningChecklist";
import CleaningServices from "../../components/CleaningServices";
import PhasedForm from "../../components/PhasedForm";
import SelectorsArea from "../../components/SelectorsArea";

function Home() {
  const bookRef = useRef(null);
  const [bedrooms, setBedrooms] = useState(1);
  const [bathrooms, setBathroom] = useState(1);
  const [frequency, setFrequency] = useState();
  const [square, setSquare] = useState("0-500");

  return (
    <Box>
      <VideoHeaderSection />
      <SelectorsArea
        value={{ bedrooms, bathrooms, frequency, square }}
        onValueChange={{
          onBedChange: (newValue) => {
            console.log("AQUi", newValue);
            setBedrooms(newValue);
          },
          onBathChange: (newValue) => {
            setBathroom(newValue);
          },
          onFreqChange: (newValue) => {
            setFrequency(newValue);
          },
          onSquareChange: (newValue) => {
            setSquare(newValue);
          },
        }}
        onButtonClick={() => {
          bookRef.current.scrollIntoView({
            behavior: "smooth",
            block: "start",
          });
        }}
      />
      <CleaningServices />

      <CleaningChecklist
        onButtonClick={() => {
          bookRef.current.scrollIntoView({
            behavior: "smooth",
            block: "start",
          });
        }}
      />

      <PhasedForm
        ref={bookRef}
        values={{ bedrooms, bathrooms, frequency, square }}
        onValueChange={{
          onBedChange: (newValue) => {
            setBedrooms(newValue);
          },
          onBathChange: (newValue) => {
            setBathroom(newValue);
          },
          onFreqChange: (newValue) => {
            setFrequency(newValue);
          },
          onSquareChange: (newValue) => {
            setSquare(newValue);
          },
        }}
      />
      <FooterSection
        onButtonClick={() => {
          bookRef.current.scrollIntoView({
            behavior: "smooth",
            block: "start",
          });
        }}
      />
    </Box>
  );
}

export default Home;
