import { styled } from "@mui/material/styles";
import { Box } from "@mui/material";

export const BookButton= styled(Box)(({ theme }) => ({
    cursor: "pointer",
    backgroundColor: "#108D92",
    color: "white",
    fontWeight: "bold",
    alignSelf: "center",
    textAlign: "center",
    width: "20%",
    minWidth:100,
    [theme.breakpoints.down("md")]: {
        padding: 10,
        fontSize: 8,
        borderRadius: 10
    },
    [theme.breakpoints.up("md")]: {
        padding: 15,
        fontSize: 15,
        borderRadius: 15
    },
    [theme.breakpoints.up("lg")]: {
        padding: 15,
        fontSize: 15,
        borderRadius: 15
    },
}));