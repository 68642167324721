import { Box, Divider, TextField, Typography } from "@mui/material";
import React from "react";
import { extraLine1 } from "./constants";
import { CardExtra, ExtraCardTitle, LineExtra } from "./style";

export default function PhaseTwoView(props) {
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
      }}
    >
      <Box>
        <Box sx={{ display: "flex", flexDirection: "row" }}>
          <Typography sx={{ color: "#108D92", marginBottom: 5 }}>
            Extra services
          </Typography>
        </Box>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <LineExtra>
            {extraLine1 &&
              extraLine1.map((extra, i) => {
                return (
                  <CardExtra
                    key={i}
                    sx={{
                      backgroundColor: props.value.extras.some(
                        (c) => c.title === extra.title
                      )
                        ? "#108D92"
                        : "white",
                      color: props.value.extras.some((c) => c.title === extra.title)
                        ? "white"
                        : "#108D92",
                    }}
                    onClick={() => {
                      props.onValueChange.onExtraChange({title: extra.title, value: extra.value});
                    }}
                  >
                    {props.value.extras.some((c) => c.title === extra.title) ? (
                      extra.icon
                    ) : (
                      <ExtraCardTitle>
                        {extra.title}
                      </ExtraCardTitle>
                    )}
                    <Typography sx={{ fontSize: 12 }}>
                      {extra.value?.toLocaleString("en-US", {
                        style: "currency",
                        currency: "USD",
                      })}
                    </Typography>
                  </CardExtra>
                );
              })}
          </LineExtra>
        </Box>
        <Box sx={{ display: "flex" }}>
          <Typography sx={{ color: "#108D92", marginBottom: 5 }}>
            Additional Instructions
          </Typography>
        </Box>
        <Box sx={{ display: "flex", flexDirection: "column" }}>
          <TextField
            label="Additional Instructions"
            placeholder="Informe any additional instruction"
            multiline
            rows={2}
            value={props.value.observation}
            onChange={(newValue) => {
              props.onValueChange.onObsChange(newValue.target.value);
            }}
          />
        </Box>
      </Box>
      <Box sx={{ alignContent: "flex-end" }}>
        <Divider />
      </Box>
    </Box>
  );
}
