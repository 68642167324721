import { styled } from "@mui/material/styles";
import { Box, Typography } from "@mui/material";

export const FormsTitle = styled(Typography)(({ theme }) => ({
  color: "#108D92",
  fontWeight: "bold",

  [theme.breakpoints.down("md")]: {
    fontSize: 15,
  },
  [theme.breakpoints.up("md")]: {
    fontSize: 25,
  },
  [theme.breakpoints.up("lg")]: {
    fontSize: 35,
  },
}));

export const BookButton = styled(Box)(({ theme }) => ({
  backgroundColor: "#108D92",
  color: "white",
  fontWeight: "bold",
  alignSelf: "center",
  textAlign: "center",
  width: "20%",
  minWidth: 100,
  [theme.breakpoints.down("md")]: {
    padding: 10,
    fontSize: 8,
    borderRadius: 10,
  },
  [theme.breakpoints.up("md")]: {
    padding: 15,
    fontSize: 15,
    borderRadius: 15,
  },
  [theme.breakpoints.up("lg")]: {
    padding: 15,
    fontSize: 15,
    borderRadius: 15,
  },
}));

export const Container = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "center",
  [theme.breakpoints.down("md")]: {
    marginBottom: 20,
    marginTop: 40,
  },
  [theme.breakpoints.up("md")]: {
    marginBottom: 20,
    marginTop: 80,
  },
  [theme.breakpoints.up("lg")]: {
    marginBottom: 20,
    marginTop: 120,
  },
}));

export const FormBox = styled(Box)(({ theme }) => ({
  width: "85%",
  backgroundColor: "#F8FCE5",
  opacity: 0.8,
  textAlign: "center",
  borderRadius: 10,
  boxShadow: 10,
  [theme.breakpoints.down("md")]: {
    padding: 10,
  },
  [theme.breakpoints.up("md")]: {
    padding: 10,
  },
  [theme.breakpoints.up("lg")]: {
    padding: 25
  },
}));
