import { styled } from "@mui/material/styles";
import { Typography, FormControl, Box } from "@mui/material";
export const SelectorLabel= styled(Typography)(({ theme }) => ({
    color: "#108D92", 
    marginBottom:5,
    [theme.breakpoints.down("md")]: {
        fontSize: 10,
    },
    [theme.breakpoints.up("md")]: {
        fontSize: 15,
    },
    [theme.breakpoints.up("lg")]: {
        fontSize: 15,
    },
}));

export const CustomFormControl= styled(FormControl)(({ theme }) => ({
    [theme.breakpoints.down("md")]: {
        minWidth: 250,
        fontSize:10
    },
    [theme.breakpoints.up("md")]: {
        minWidth: 200,
        fontSize:15
    },
    [theme.breakpoints.up("lg")]: {
        minWidth: 200,
        fontSize:15
    },
}));


export const Container= styled(Box)(({ theme }) => ({
    width: "100%", 
    height: "auto", 
    backgroundColor: "#F8FCE5",
    marginTop: -5, 
    paddingTop:30,
    paddingBottom:40,
    display: "flex", 
    justifyContent: "space-evenly", 
    rowGap:10,
    flexDirection: "row",
    flexWrap: "wrap",
    alignItems: "center",
    [theme.breakpoints.down("md")]: {
        
        fontSize:10
    },
    [theme.breakpoints.up("md")]: {
        fontSize:15
    },
    [theme.breakpoints.up("lg")]: {
        fontSize:15
    },
}));

export const BookButton= styled(Box)(({ theme }) => ({
    cursor: "pointer",
    backgroundColor: "#108D92",
    color: "white",
    fontWeight: "bold",
    alignSelf: "center",
    textAlign: "center",
    width: "35%",
    [theme.breakpoints.down("md")]: {
        padding: 10,
        fontSize: 10,
        borderRadius: 10,
        marginTop:-20
    },
    [theme.breakpoints.up("md")]: {
        padding: 15,
        fontSize: 15,
        borderRadius: 15,
        marginTop:-20
    },
    [theme.breakpoints.up("lg")]: {
        padding: 15,
        fontSize: 25,
        borderRadius: 15,
        marginTop:-30,
    },
}));