import { 
    LocalLaundryService, Microwave, CleaningServices, Blinds,
    Garage, Kitchen, VerticalShades, Countertops, Wallpaper, EmojiFoodBeverage,
    Restaurant, FoodBank, BedroomParent
} from '@mui/icons-material';
export const extraLine1 = [
    {
        title:"Laundry",
        icon: <LocalLaundryService fontSize='large'/>,
        value: 10        
    },
    {
        title:"Inside Microwave",
        icon: <Microwave fontSize='large'/>,
        value: 10
    },
    {
        title:"Heavy Duty Deep Cleaning",
        icon: <CleaningServices fontSize='large'/>,
        value: 125
    },
    {
        title:"Blinds per Set",
        icon: <Blinds fontSize='large'/>,
        value: 5
    },
    {
        title:"Garage of Patio",
        icon: <Garage fontSize='large'/>,
        value: 35
    },
    {
        title:"Inside Kitchen Cabinet",
        icon: <FoodBank fontSize='large'/>,
        value: 20
    },
    {
        title:"Windows (inside)",
        icon: <Wallpaper fontSize='large'/>,
        value: 5
    },
    {
        title:"Inside oven",
        icon: <Countertops fontSize='large'/>,
        value: 15
    },
    {
        title:"Wall Cleaning",
        icon: <VerticalShades fontSize='large'/>,
        value: 50
    },
    {
        title:"Washing Dishes",
        icon: <EmojiFoodBeverage fontSize='large'/>,
        value: 20
    },
    {
        title:"Dishwasher",
        icon: <Restaurant fontSize='large'/>,
        value: 10
    },
    {
        title:"Inside Refriferator",
        icon: <Kitchen fontSize='large'/>,
        value: 30
    },
    {
        title:"Extra Room",
        icon: <BedroomParent fontSize='large'/>,
        value: 20
    },
]

export const extraLine2 = [
    
]

export const extras=[
    
]