import { CardDescription, MarketingContainer, CardTitle } from "./style";
import { Box } from "@mui/material";
import Carousel from "react-elastic-carousel";
import { data } from "./constants";
import { useRef } from "react";

const breakPoints = [
  { width: 1, itemsToShow: 1 },
  { width: 550, itemsToShow: 1 },
  { width: 768, itemsToShow: 4 },
];

export default function CleaningServices(props) {
  const carouselRef = useRef(null);
  let resetTimeout;

  return (
    <Box sx={{ display: "flex", flexDirection: "column", alignItems: "center", marginTop: 10, rowGap: 5 }}>
      <label style={{ color: "#108D92", fontWeight: "bold", fontSize: 35 }}>Cleaning Services</label>
      <Box sx={{ width: "100%", marginBottom: 10 }}>
        <Carousel
          ref={carouselRef}
          breakPoints={breakPoints}
          pagination={false}
          enableAutoPlay={true}
          showArrows={false}
          autoPlaySpeed={5000}
          isRTL={false}
          onNextEnd={({ index }) => {
            clearTimeout(resetTimeout);
            resetTimeout = setTimeout(() => {
              carouselRef?.current?.goTo(0);
            }, 5000); // same time
          }}>
          {data.map((item, index) => {
            return (
              <MarketingContainer key={index}>
                <Box style={{ display: "flex", flexDirection: "row", justifyContent: "center", marginBottom: 5 }}>
                  <img src={item.image} style={{ width: 200, height: 200, objectFit: "cover" }} />
                </Box>
                <CardTitle>{item.title}</CardTitle>
                <CardDescription>{item.description}</CardDescription>
              </MarketingContainer>
            );
          })}
        </Carousel>
      </Box>
    </Box>
  );
}
