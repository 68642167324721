import { BookButton } from "./style";
import { Box } from "@mui/material";

export default function SelectorsArea(props) {
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "row",
        width: "100%",
        justifyContent: "center",
        position: "absolute",
      }}
    >
      <BookButton
        onClick={() => {
          props?.onButtonClick();
        }}
      >
        BOOK NOW
      </BookButton>
    </Box>
  );
}
